import React, { useState,useEffect } from 'react'
import MpesaLogo from "../Data/Assests/mpesalogo.png"
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
const PaymentOptionsPage = () => {
    const navigate = useNavigate();

    // Function to handle the back button click
    const handleBack = () => {
        // Go back to the previous page
        navigate(-1);
    };
    const [paymentOption, setPaymentOption] = useState("mpesa");

    const handlePaymentOptionChange = (option) => {
        setPaymentOption(option);
    };
    const handleNextStep = () => {
        // Store formData to Local Storage

        
        localStorage.setItem('paymentOption', JSON.stringify(paymentOption));

        navigate("/mpesa-payment-details")
    }
    return (
        <div className='flex flex-col h-screen px-5 py-7' >

            <div className='flex items-center border-b pb-5 border-custom-light-gray'>
                <div className='flex'>
                    <div>
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.625 7.71875C1.625 6.74905 2.01021 5.81907 2.69589 5.13339C3.38157 4.44771 4.31155 4.0625 5.28125 4.0625H20.7188C21.6884 4.0625 22.6184 4.44771 23.3041 5.13339C23.9898 5.81907 24.375 6.74905 24.375 7.71875V18.2812C24.375 19.2509 23.9898 20.1809 23.3041 20.8666C22.6184 21.5523 21.6884 21.9375 20.7188 21.9375H5.28125C4.31155 21.9375 3.38157 21.5523 2.69589 20.8666C2.01021 20.1809 1.625 19.2509 1.625 18.2812V7.71875ZM5.28125 5.6875C4.74253 5.6875 4.22587 5.90151 3.84494 6.28244C3.46401 6.66337 3.25 7.18003 3.25 7.71875V8.9375H22.75V7.71875C22.75 7.18003 22.536 6.66337 22.1551 6.28244C21.7741 5.90151 21.2575 5.6875 20.7188 5.6875H5.28125ZM3.25 18.2812C3.25 18.82 3.46401 19.3366 3.84494 19.7176C4.22587 20.0985 4.74253 20.3125 5.28125 20.3125H20.7188C21.2575 20.3125 21.7741 20.0985 22.1551 19.7176C22.536 19.3366 22.75 18.82 22.75 18.2812V10.5625H3.25V18.2812ZM17.0625 15.4375H19.5C19.7155 15.4375 19.9222 15.5231 20.0745 15.6755C20.2269 15.8278 20.3125 16.0345 20.3125 16.25C20.3125 16.4655 20.2269 16.6722 20.0745 16.8245C19.9222 16.9769 19.7155 17.0625 19.5 17.0625H17.0625C16.847 17.0625 16.6403 16.9769 16.488 16.8245C16.3356 16.6722 16.25 16.4655 16.25 16.25C16.25 16.0345 16.3356 15.8278 16.488 15.6755C16.6403 15.5231 16.847 15.4375 17.0625 15.4375Z" fill="#CD3234" />
                        </svg>

                    </div>
                    <h1 className='font-sans font-bold text-lg ml-2'>
                        Payment option
                    </h1>
                </div>
                <div className='flex ml-auto gap-5 items-center'>
                    <button onClick={handleBack}>
                        <div className='flex bg-box-gray justify-center items-center w-16 h-7 rounded'>
                            <h1 className='text-xs text-custom-gray font-semibold font-sans'>Back</h1>
                        </div>
                    </button>
                    {/* <div className='flex bg-box-red justify-center items-center w-16 h-7 rounded'>
                            <h1 className='text-xs text-custom-red font-semibold font-sans'>Close</h1>
                        </div> */}
                    <div className='flex  justify-center items-center w-7 h-7 rounded-full'>
                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.845 12.7182C13.9947 12.8679 14.0788 13.071 14.0788 13.2827C14.0788 13.4944 13.9947 13.6974 13.845 13.8471C13.6953 13.9968 13.4923 14.0809 13.2806 14.0809C13.0688 14.0809 12.8658 13.9968 12.7161 13.8471L8.49997 9.62966L4.28251 13.8458C4.13281 13.9955 3.92977 14.0796 3.71806 14.0796C3.50635 14.0796 3.30331 13.9955 3.1536 13.8458C3.0039 13.6961 2.9198 13.4931 2.9198 13.2813C2.9198 13.0696 3.0039 12.8666 3.1536 12.7169L7.37107 8.50076L3.15493 4.2833C3.00523 4.13359 2.92113 3.93055 2.92113 3.71884C2.92113 3.50713 3.00523 3.30409 3.15493 3.15439C3.30463 3.00469 3.50767 2.92059 3.71939 2.92059C3.9311 2.92059 4.13414 3.00469 4.28384 3.15439L8.49997 7.37185L12.7174 3.15373C12.8671 3.00402 13.0702 2.91992 13.2819 2.91992C13.4936 2.91992 13.6966 3.00402 13.8463 3.15373C13.996 3.30343 14.0801 3.50647 14.0801 3.71818C14.0801 3.92989 13.996 4.13293 13.8463 4.28263L9.62888 8.50076L13.845 12.7182Z" fill="#CD3234" />
                        </svg>



                    </div>
                </div>
            </div>
            <div>
                <p className='font-sans font-medium mt-7 text-custom-gray text-sm'>
                    Please select preferred payment option to Proceed
                </p>
            </div>
            {/* link to mpesa payment details component*/}

            <div>
                <div className="flex flex-col justify-center mt-10 space-y-4">
                    <div className='border-custom-gray border  rounded-lg px-5'>
                        <label className="flex items-center space-x-2 cursor-pointer">
                            <input
                                type="radio"
                                name="paymentOption"
                                value="mpesa"
                                checked={paymentOption === "mpesa"}
                                onChange={() => handlePaymentOptionChange("mpesa")}
                                className="text-custom-red border-custom-red focus:ring-custom-red"
                            />
                            <div className='flex w-full'>
                                <div className="flex  bg-white rounded-md   h-20 w-32 ml-auto">
                                    <img src={MpesaLogo} alt="" />
                                </div>
                            </div>

                        </label>
                    </div>


                </div>

            </div>
            <div className='flex    mt-auto mb-20'>
                
                    <div className='flex w-full justify-center' onClick={handleNextStep}>

                        <button className='bg-custom-red w-full h-14 rounded-xl lg:w-96'>
                            <h1 className='text-white font-sans font-bold '>Proceed</h1>
                        </button>


                    </div>
                
            </div>



        </div>
    )
}

export default PaymentOptionsPage
