import React, { useState, useEffect } from 'react'
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Textarea,
    Text,
    Box,
    InputGroup,
    InputLeftAddon
} from '@chakra-ui/react'
import { Outlet, Link } from "react-router-dom"
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
const MpesaPaymentDetailsPage = () => {
    const navigate = useNavigate();
    const [isloading, setIsLoading] = useState(false)
    const [isdisabled, setIsDisabled] = useState(false)
    const [mpesaResponse, setMpesaResponse] = useState(null)
    const [stkpushphoneNumber, setStkPushPhoneNumber] = useState()
    const [totalprice, setTotalPrice] = useState(0);
    // Function to handle the back button click
    const handleBack = () => {
        // Go back to the previous page
        navigate(-1);
    };

    const handlecallback = async () => {
        try {
            const response = await axios.post("https://swiftrewardsbackend.onrender.com/mpesa/callback")

            if (response.data) {
                console.log(response.data)
            }
            else {
                console.log("no mpesa ress found")
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        // Retrieve state from Local Storage
        const fullNames = JSON.parse(localStorage.getItem('fullNames'));
        const email = JSON.parse(localStorage.getItem('email'));
        const comment = JSON.parse(localStorage.getItem('comment'));
        const rate = JSON.parse(localStorage.getItem('rate'));
        const paymentOption = JSON.parse(localStorage.getItem('paymentOption'));

        const selectedNumber = JSON.parse(localStorage.getItem('selectedNumber'));
        const tankPrice = JSON.parse(localStorage.getItem('tankPrice'));
        const tankAmount = JSON.parse(localStorage.getItem('tankAmount'));
        const totalprice = JSON.parse(localStorage.getItem('totalprice'));
        setTotalPrice(totalprice)


    }, []);
    // stkpush function api
    const stkpushapi = async () => {
        try {
            if (totalprice && stkpushphoneNumber) {
                // alert(stkpushphoneNumber)
                // console.log(stkpushphoneNumber)
                setIsLoading(true)
                setIsDisabled(true)
                const fanDetails = sessionStorage.getItem('fanDetails');
                const sessionTransactionId = sessionStorage.getItem('sessionTransactionId')
                const creatorId = sessionStorage.getItem('creatorId')
                const fanTransactionId = sessionStorage.getItem('fanTransactionId')
                //take all the transaction data to a transaction log

                const response = await axios.post("https://fuelbackend.onrender.com/api/mpesa/stkpush", {
                    amount: totalprice, phoneNumber: stkpushphoneNumber, fanDetails, sessionTransactionId, creatorId, fanTransactionId
                })
                // const response = await axios.post("http://192.168.100.197:3500/api/mpesa/stkpush", {
                //     amount: totalprice, phoneNumber: stkpushphoneNumber, fanDetails, sessionTransactionId, creatorId, fanTransactionId
                // })

                if (response) {
                    setIsLoading(false)
                    setIsDisabled(false)
                    const { mpesaresponse } = response.data
                    // const MerchantRequestID =mpesaresponse.MerchantRequestID
                    // const CheckoutRequestID=mpesaresponse.CheckoutRequestID
                    setMpesaResponse(mpesaresponse)

                    console.log(response.data)
                    console.log(mpesaresponse)
                }
            } else {
                alert("stk data not found")
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
            console.log("error promting stkpush", error)
        }
    }
    const handleClick = () => {

    }

    useEffect(() => {
        let timeoutId;

        const checkTransactionStatus = async () => {
            if (mpesaResponse !== null) {
                const { MerchantRequestID, CheckoutRequestID } = mpesaResponse;
                if (MerchantRequestID && CheckoutRequestID) {
                    try {
                        const response = await axios.get("https://fuelbackend.onrender.com/api/mpesa/mpesaTransactionStatus", {
                            params: {
                                MerchantRequestID,
                                CheckoutRequestID
                            }
                        });
                        const { transaction } = response.data;
                        alert(transaction.ResultDesc);
                        // console.log("transaction data",transaction)
                        // Check if the desired condition is met
                        if (transaction.Status !== null) {
                            // Stop further fetching by not scheduling the next call
                            return;
                        }
                    } catch (error) {
                        console.error("Error fetching transaction status:", error);
                    }
                }
            }

            // Schedule the next call after a short delay
            timeoutId = setTimeout(checkTransactionStatus, 0);
        };

        checkTransactionStatus(); // Start fetching initially

        // Clear timeout when component unmounts or when you want to stop fetching
        return () => clearTimeout(timeoutId);
    }, [mpesaResponse]);



    // stkpush response
    // navlink to a component based on the transaction status

    return (
        <div className='flex flex-col h-screen px-5 py-7' >
            <div>
                <div>
                    <div className='flex items-center border-b border-custom-light-gray pb-5'>
                        <div className='flex items-center'>
                            <div>
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13 5H21M13 9H18M13 15H21M13 19H18M3 5C3 4.73478 3.10536 4.48043 3.29289 4.29289C3.48043 4.10536 3.73478 4 4 4H8C8.26522 4 8.51957 4.10536 8.70711 4.29289C8.89464 4.48043 9 4.73478 9 5V9C9 9.26522 8.89464 9.51957 8.70711 9.70711C8.51957 9.89464 8.26522 10 8 10H4C3.73478 10 3.48043 9.89464 3.29289 9.70711C3.10536 9.51957 3 9.26522 3 9V5ZM3 15C3 14.7348 3.10536 14.4804 3.29289 14.2929C3.48043 14.1054 3.73478 14 4 14H8C8.26522 14 8.51957 14.1054 8.70711 14.2929C8.89464 14.4804 9 14.7348 9 15V19C9 19.2652 8.89464 19.5196 8.70711 19.7071C8.51957 19.8946 8.26522 20 8 20H4C3.73478 20 3.48043 19.8946 3.29289 19.7071C3.10536 19.5196 3 19.2652 3 19V15Z" stroke="#CD3234" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>


                            </div>
                            <h1 className='font-sans font-bold text-lg ml-2'>
                                Mpesa details
                            </h1>
                        </div>
                        <div className='flex ml-auto gap-5 items-center'>
                            <button onClick={handleBack}>
                                <div className='flex bg-box-gray justify-center items-center w-16 h-7 rounded'>
                                    <h1 className='text-xs text-custom-gray font-semibold font-sans'>Back</h1>
                                </div>
                            </button>
                            {/* <div className='flex bg-box-red justify-center items-center w-16 h-7 rounded'>
                            <h1 className='text-xs text-custom-red font-semibold font-sans'>Close</h1>
                        </div> */}
                            <div className='flex  justify-center items-center w-7 h-7 rounded-full'>
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.845 12.7182C13.9947 12.8679 14.0788 13.071 14.0788 13.2827C14.0788 13.4944 13.9947 13.6974 13.845 13.8471C13.6953 13.9968 13.4923 14.0809 13.2806 14.0809C13.0688 14.0809 12.8658 13.9968 12.7161 13.8471L8.49997 9.62966L4.28251 13.8458C4.13281 13.9955 3.92977 14.0796 3.71806 14.0796C3.50635 14.0796 3.30331 13.9955 3.1536 13.8458C3.0039 13.6961 2.9198 13.4931 2.9198 13.2813C2.9198 13.0696 3.0039 12.8666 3.1536 12.7169L7.37107 8.50076L3.15493 4.2833C3.00523 4.13359 2.92113 3.93055 2.92113 3.71884C2.92113 3.50713 3.00523 3.30409 3.15493 3.15439C3.30463 3.00469 3.50767 2.92059 3.71939 2.92059C3.9311 2.92059 4.13414 3.00469 4.28384 3.15439L8.49997 7.37185L12.7174 3.15373C12.8671 3.00402 13.0702 2.91992 13.2819 2.91992C13.4936 2.91992 13.6966 3.00402 13.8463 3.15373C13.996 3.30343 14.0801 3.50647 14.0801 3.71818C14.0801 3.92989 13.996 4.13293 13.8463 4.28263L9.62888 8.50076L13.845 12.7182Z" fill="#CD3234" />
                                </svg>



                            </div>
                        </div>

                    </div>

                    <p className='font-sans font-medium mt-7 text-custom-gray text-sm'>
                        Please enter your registered Mpesa number to receive a payment prompt
                    </p>
                </div>
                <div className='mt-7'>
                    <FormLabel><h1 className='font-sans text-sm font-semibold mb-2'>Valid mpesa number</h1></FormLabel>
                    <InputGroup >

                        <InputLeftAddon>+254</InputLeftAddon>
                        <Input type='tel' placeholder='758756100' value={stkpushphoneNumber} onChange={(e) => setStkPushPhoneNumber(e.target.value)} />
                    </InputGroup>
                </div>
            </div>
            <div className='flex    mt-auto mb-20'>

                <div className='flex w-full justify-center'>

                    <button className='flex bg-custom-red w-full justify-center items-center h-14 rounded-xl lg:w-96' onClick={stkpushapi} disabled={isdisabled}>

                        {
                            isloading === false ? (<div> <h1 className='text-white font-sans font-bold '>
                                Pay {totalprice}</h1></div>) : (<span className=" flex loading loading-infinity items-center justify-center bg-white loading-lg"></span>)
                        }

                    </button>


                </div>

            </div>

        </div>
    )
}

export default MpesaPaymentDetailsPage