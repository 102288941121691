import React from 'react';
import { Suspense, lazy } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import CreatorPage, { creatorDataLoader } from './Pages/CreatorPage';
import FanDetailsPage from './Components/FanDetailsPage';
import PaymentOptionsPage from './Components/PaymentOptionsPage';
import MpesaPaymentDetailsPage from './Components/MpesaPaymentDetailsPage';
import SuccessPaymentComponent from './Components/SuccessPaymentComponent';
import ErrorPage from './Pages/ErrorPage';

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Routes
} from 'react-router-dom';

// Define your routes
const router = createBrowserRouter(
  createRoutesFromElements(
  
      <Route errorElement={<ErrorPage />}>
          <Route index path='/:username' element={<CreatorPage />} loader={creatorDataLoader} errorElement={<ErrorPage />} />
       

        <Route path="/fandetails" element={<FanDetailsPage />} errorElement={<ErrorPage />} />
        <Route path="payment-options" element={<PaymentOptionsPage errorElement={<ErrorPage />} />} />

        <Route path="mpesa-payment-details" element={<MpesaPaymentDetailsPage errorElement={<ErrorPage />} />} />
        <Route path="success" element={<SuccessPaymentComponent />} /> {/* Uncommented route */}
        
        <Route path="*" element={<ErrorPage />} />
      </Route>
    
  )
);

function App() {
  return (
    <ChakraProvider>
      <RouterProvider router={router} />
    </ChakraProvider>
  );
}

export default App;
