import React from 'react';
import { useField } from 'formik';
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb, Box } from '@chakra-ui/react';

const CustomSlider = ({ ...props }) => {
    const [field, , { setValue }] = useField(props);

    return (
        <Slider
            aria-label="slider-ex-1"
            value={field.value || 0}
            min={0}
            max={10}
            step={1}
            onChange={(val) => setValue(val)}
            {...props}
        >
            <SliderTrack>
                <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={6}>
                <Box w="100%" bg="tomato" />
            </SliderThumb>
        </Slider>
    );
};

export default CustomSlider;
