import React from 'react'
import { Badge } from '@chakra-ui/react'
const SuccessPaymentComponent = () => {
    return (
        <div className='flex flex-col justify-center items-center h-screen'>
            <div className='flex'>
                <svg width="134" height="138" viewBox="0 0 134 138" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="133.241" height="137.999" rx="66.6203" fill="#FFF8F8" />
                    <path d="M86.365 81.4481C87.7883 81.4481 88.9882 81.9446 89.9646 82.9376C90.9411 83.9306 91.421 85.0891 91.4045 86.4131L71.4702 93.8605L54.0928 88.8956V66.5533H58.9336L76.9813 73.2311C78.2722 73.7442 78.9176 74.671 78.9176 76.0115C78.9176 76.7893 78.6363 77.4679 78.0736 78.0471C77.5109 78.6264 76.7992 78.9326 75.9386 78.9657H68.9877L64.6433 77.3024L63.8241 79.6359L68.9877 81.4481H86.365ZM76.4351 47.2644C78.1894 45.2288 80.4236 44.2109 83.1378 44.2109C85.3886 44.2109 87.2918 45.0384 88.8475 46.6934C90.4032 48.3484 91.2307 50.2516 91.33 52.4031C91.33 54.1078 90.5025 56.1434 88.8475 58.51C87.1925 60.8767 85.5624 62.8544 83.957 64.4431C82.3517 66.0319 79.8444 68.3903 76.4351 71.5182C72.9927 68.3903 70.4606 66.0319 68.8387 64.4431C67.2168 62.8544 65.5867 60.8767 63.9482 58.51C62.3098 56.1434 61.5071 54.1078 61.5402 52.4031C61.5402 50.1523 62.3429 48.2491 63.9482 46.6934C65.5536 45.1377 67.4899 44.3102 69.7572 44.2109C72.4052 44.2109 74.6312 45.2288 76.4351 47.2644ZM39.1582 66.5533H49.1278V93.8605H39.1582V66.5533Z" fill="#CD3234" />
                </svg>



            </div>
            <div className='mt-5'>
                <Badge colorScheme='green'>Success</Badge>
            </div>
            <div className='mt-3'>
                <h1 className='font-sans text-sm text-custom-gray font-medium'>Payment was successful</h1>
                <h1 className='font-sans text-sm text-custom-gray font-medium text-center'>Thank you</h1>
            </div>
            <div className='mt-40 pb-20'>
                <button><h1 className='font-sans text-sm font-medium text-custom-red'>Create a fuel my build account now</h1></button>
            </div>
        </div>
    )
}

export default SuccessPaymentComponent